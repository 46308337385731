<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.office_type_id"
                                :options="officeTypeList"
                                id="office_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="office_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="roomRentData.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Guest House" vid="guest_house_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="guest_house_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.guest_house')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.guest_house_id"
                                :options="guestHouseList"
                                id="guest_house_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <!-- <ValidationProvider name="Type" vid="type" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="type"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="roomRentData.type"
                                    :options="typeList"
                                    id="type"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider> -->
                            <ValidationProvider name="Room Type" vid="room_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="room_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.room_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="roomRentData.room_type_id"
                                :options="roomTypeList"
                                id="room_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Own Organization" vid="own_org_price" rules="required" v-if="$store.state.Auth.authUser.org_id !== 9 && roomRentData.org_id !== 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="own_org_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.own_org')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="own_org_price"
                                    v-model="roomRentData.own_org_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Own Organization" vid="own_org_price" rules="required"  v-if="$store.state.Auth.authUser.org_id == 9 || roomRentData.org_id == 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="own_org_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.own_org1')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="own_org_price"
                                    v-model="roomRentData.own_org_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Govt" vid="govt_price" rules="required" v-if="$store.state.Auth.authUser.org_id !== 9 && roomRentData.org_id !== 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="govt_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.govt')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="govt_price"
                                    v-model="roomRentData.govt_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Govt" vid="govt_price" rules="required"  v-if="$store.state.Auth.authUser.org_id == 9 || roomRentData.org_id == 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="govt_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.govt1')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="govt_price"
                                    v-model="roomRentData.govt_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Private" vid="private_price" rules="required" v-if="$store.state.Auth.authUser.org_id !== 9 && roomRentData.org_id !== 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="private_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.private')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="private_price"
                                    v-model="roomRentData.private_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Private" vid="private_price" rules="required" v-if="$store.state.Auth.authUser.org_id == 9 || roomRentData.org_id == 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="private_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.non_govt')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="private_price"
                                    v-model="roomRentData.private_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Domestic and Foreign Consultants" vid="domestic_foreign_consultants_price" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="domestic_foreign_consultants_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.domestic_foreign_consultants')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="domestic_foreign_consultants_price"
                                    v-model="roomRentData.domestic_foreign_consultants_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Nars Grade10" vid="nars_grade10_price" v-if="($store.state.Auth.authUser.org_id == 9 && roomRentData.office_type_id !== 118) || (roomRentData.org_id == 9 && roomRentData.office_type_id !== 118)">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="nars_grade10_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.own_org2')}}
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="nars_grade10_price"
                                    v-model="roomRentData.nars_grade10_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Rent Non Govt" vid="non_govt_price" v-if="($store.state.Auth.authUser.org_id == 9 && roomRentData.office_type_id !== 118) || (roomRentData.org_id == 9 && roomRentData.office_type_id !== 118)">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="non_govt_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.non_govt1')}}
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="non_govt_price"
                                    v-model="roomRentData.non_govt_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Farmer" vid="farmer_price" v-if="($store.state.Auth.authUser.org_id == 9 && roomRentData.office_type_id !== 118) || (roomRentData.org_id == 9 && roomRentData.office_type_id !== 118)">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="farmer_price"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.farmer_rent')}}
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="farmer_price"
                                    v-model="roomRentData.farmer_price"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="VAT/Tax (%)" vid="vat_tax" rules="required" v-if="roomRentData.org_id !== 9">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="vat_tax"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.vat_tax')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    type="number"
                                    id="vat_tax"
                                    v-model="roomRentData.vat_tax"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="remarks"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks"
                                        v-model="roomRentData.remarks"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks_bn"
                                        v-model="roomRentData.remarks_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { roomRentStore, roomRentUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getRoomRentData()
            this.roomRentData = tmp
        } else {
            this.roomRentData = Object.assign({}, this.roomRentData, {
                org_id: this.$store.state.dataFilters.orgId,
                fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
            })
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
        loading: false,
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        roomRentData: {
            id: '',
            fiscal_year_id: 0,
            office_type_id: 0,
            office_id: 0,
            org_id: 0,
            type: 0,
            room_type_id: 0,
            guest_house_id: 0,
            own_org_price: '',
            nars_grade10_price: '',
            govt_price: '',
            private_price: '',
            non_govt_price: '',
            domestic_foreign_consultants_price: '',
            farmer_price: '',
            vat_tax: '',
            remarks: '',
            remarks_bn: '',
            status: 1
        },
        officeTypeList: [],
        officeList: [],
        guestHouseList: [],
        roomTypeList: []
        }
    },
    computed: {
        orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        // roomTypeList: function () {
        //   return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        // },
        fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        typeList: function () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? 'AC' : 'এসি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Non AC' : 'নন এসি' }
            ]
        }
    },
    watch: {
        'roomRentData.org_id': function (newValue) {
            this.officeTypeList = this.getOfficeTypeList(newValue)
        },
        'roomRentData.office_type_id': function (newValue) {
            this.officeList = this.getOfficeList(newValue)
        },
        'roomRentData.office_id': function (newVal) {
            this.guestHouseList = this.guestList(newVal)
        },
        'roomRentData.guest_house_id': function (newVal) {
            this.roomTypeList = this.getRoomTypeList(newVal)
        }
        // 'roomRentData.type': function (newVal) {
        //     this.roomTypeList = this.getRoomTypeList(newVal)
        // }
    },
    methods: {
        getRoomRentData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getOfficeTypeList (orgId) {
            const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                return office.filter(item => item.org_id === orgId)
            }
            return office
        },
        getOfficeList (officeTypeId = null) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
            if (officeTypeId) {
                return officeList.filter(office => office.office_type_id === officeTypeId)
            }
            return officeList
        },
        guestList (officeId) {
            const guest = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
            if (officeId) {
                return guest.filter(item => item.office_id === officeId)
            }
            return guest
        },
        getRoomTypeList (guestHouseId) {
            const guest = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
            if (guestHouseId) {
                return guest.filter(item => item.guest_house_id === guestHouseId)
            }
            return guest
        },
        // getRoomTypeList (type) {
        //     const guest = this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        //     if (type) {
        //         return guest.filter(item => item.type === type)
        //     }
        //     return guest
        // },
        async createData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }

            if (this.roomRentData.id) {
                result = await RestApi.putData(trainingElearningServiceBaseUrl, `${roomRentUpdate}/${this.id}`, this.roomRentData)
            } else {
                result = await RestApi.postData(trainingElearningServiceBaseUrl, roomRentStore, this.roomRentData)
            }

            loadinState.listReload = true

            this.$store.dispatch('mutateCommonProperties', loadinState)

            if (result.success) {
                this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loading = false
        }
    }
}
</script>
